import React from 'react';
import { useParams } from 'react-router-dom';

import LoadAllBlogs from './Blog/LoadAllBlogs';
import LoadOneBlog from './Blog/LoadOneBlog';

const Blog = props => {
  let { id } = useParams();

  if (!id) {
    return <LoadAllBlogs />
  } else {
    return <LoadOneBlog id={id} />
  }
}

export default Blog;