import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { getRandomBuddhaWords } from '../../util/apiUtils';

const BuddhaWords = props => {
  const [ buddhWords, setBuddhWords] = useState(null);

  useEffect(() => {
    const getBuddhaWords = async() => {
      const result = await getRandomBuddhaWords();
      const teacherwords = result.buddhaWords;
      if (Array.isArray(teacherwords) && teacherwords.length > 0) {
        const theBuddhaWord = teacherwords[0].teacherwords;
        setBuddhWords(theBuddhaWord);
      }
    }
    
    getBuddhaWords();
  }, []);

  const connerStyle = {
    width: 35,
    height: 35,
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent'
  };

  const styles = {
    buddhaWords: {
      backgroundColor: '#f6f6f6',
      position: 'relative',
      paddingBottom: 30
    },
    topLeft: {
      ...connerStyle,
      top: 10,
      left: 10,
      backgroundImage: 'url("/img/home/corner1.png")'
    },
    topRight: {
      ...connerStyle,
      top: 10,
      right: 10,
      backgroundImage: 'url("/img/home/corner1.png")',
      transform: 'rotate(90deg)'
    },
    bottomLeft: {
      ...connerStyle,
      bottom: 10,
      left: 10,
      backgroundImage: 'url("/img/home/corner1.png")',
      transform: 'rotate(-90deg)'
    },
    bottomRight: {
      ...connerStyle,
      bottom: 10,
      right: 10,
      backgroundImage: 'url("/img/home/corner1.png")',
      transform: 'rotate(180deg)'
    },
    title: {
      textAlign: 'center', 
      marginTop: 20, 
      color: '#999999', 
      marginBottom: 20
    }
  }

  return (
    <Grid container style={styles.buddhaWords}>
        <div style={styles.topLeft}></div>
        <div style={styles.topRight}></div>
        <div style={styles.bottomLeft}></div>
        <div style={styles.bottomRight}></div>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="div"
              xs={12}
              style={ styles.title }
            >
              仙佛慈語
            </Typography>
          </Grid>

          <Grid item xs={1} />

          <Grid item xs={10}>
            <Typography
              component="div"
              style={{ textAlign: 'center' }}
            >
              { buddhWords }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default BuddhaWords;