import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import VegeHeader from './Vege/VegeHeader';
import VegeContent from './Vege/VegeContent';

const Vege = props => {
  const styles = {
    titleBlock: {
      maxHeight: 350,
      overflow: 'hidden'
    },
    vegeImage: {
      width: '100%'
    },
    vegeDescription: {
      padding: 20
    },
    title: {
      width: '100%',
      textAlign: 'center',
      marginTop: 20
    }
  };

  return (
    <Grid
      container
      direction='row'
    >
      <VegeHeader />
      
      <Grid
        item
        xs={12}
        style={styles.title}
      >
        <Typography
          variant="h4"
          component="div"
        >
          美味食譜
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <VegeContent />
      </Grid>
    </Grid>
  )
}

export default Vege;