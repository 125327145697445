import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AboutHeader from './About/AboutHeader';

const History = props => {
  const styles = {
    subtitle: {
      width: '100%',
      textAlign: 'center',
      marginTop: 20
    },
    aboutVideo: {
      marginTop: 20
    },
    aboutVideoIframe: {
      width: 560,
      height: 315,
      border: 0
    }
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
    >
      <AboutHeader />

      <Typography
        variant="h5"
        component="div"
        style={styles.subtitle}
      >
        緣起
      </Typography>

      <Grid
        item
        container
        direction='row'
      >
        <Grid item xs={1} />
        <Grid item xs={10}>
          <p>天恩浩瀚，師德無邊，白水聖帝、不休息菩薩在天之靈庇佑，天道非時不降、非人不傳，末後一著是天人共辦的收圓大聖業，天道是順應天時，普收三曹有緣佛子，並非靠人力所能勝任的，很多人疏忽了這是天人合一，才能推行。諸天神聖、萬仙菩薩皆來打幫助道，所以大道普傳之處，風行草偃，為這末後一大事因緣而來，因此有無數的原胎佛子，加入普渡眾生的行列，開設佛堂，代天宣化。</p>
          <p>追溯西元一九七五年&gt;追溯西元一九七五年，紀碧梅女士（周媽媽）移民來美國，初到美國生活環境極為艱辛。定居十多年後也安平樂道非常樸實，在大女兒周宏華的陪同下，引渡父母親求道，父母親自得道以來，深刻體悟道的尊貴，便發心於一九九0年設立發一崇德舊金山周氏家庭佛堂。</p>
          <p>並申請兒女移居美國，當時周講師宏華內心很徬徨，舉棋不定：假如來美國，一切要從頭開始，語言不通，環境陌生；而在台灣，工作、生活極為穩定，家裡又設有薛氏佛堂可以辦道渡人，且先生─薛講師為空軍職業軍人，生活待遇優厚，所以全家移民之事暫擱一旁。翌年一九九二年七月，台灣立法院立法通過職業軍人服務滿十年可以退伍，薛講師認為趁年輕可以來美國開創一番，於是便遞交了退伍申請表。西元一九九三年二月二十八日全家終於移民美國舊金山。</p>
          <p>當年舊金山地區還沒有發一崇德道場，後來得知在聖荷西有發一崇德聖德佛堂，於是每個週末往返舊金山─聖荷西兩地學習幫辦道務。 西元一九九四年聖德佛堂開複習班，活佛老師臨壇走到宏華身邊點點頭說：『妳來了！』剎那間，她才領悟到，原來這一切皆是上天的安排。</p><p>在天恩師德加被，師尊、師母慈悲撥轉，有緣佛子陸續上岸，當時請聖荷西 胡經理慈悲來到舊金山點道，在辦道過程中，有時候求道人未來，或有人臨時要求道，諸如此類變化對胡經理造成諸多不便，非常歉疚。</p>
          <p>白水聖帝在世時，曾開金口慈悲指示黃老經理『出去開荒』。藉此機緣，宏華邀請台灣三重區黃老經理新契和三女兒（黃講師高秀）一同前來舊金山長住，半年後，再由黃經理美鳳接班。黃點傳師一家為道不辭勞苦精之神，令後學們感佩。</p>
          <p>一九九四年道親人數漸漸增多，周氏佛堂已不夠使用，在幾番找尋之後，深蒙  上天慈悲、萬仙菩薩助道，終於順利地於日落區買下現在的沾德佛堂，並於一九九四年七月九日成立了發一崇德舊金山沾德公共佛堂。</p>
          <p>沾德佛堂開第一班法會是在二樓的客廳，後因人數漸多，便移至後院的花房。早期的花房，買來後因無錢整修，僅是經過簡單清理打掃。後來道親日益增多，才將花房進行了大翻修，並配備了基本設施，使之符合道場開班、辦活動使用。</p>
          <p>西元一九九八年四月四日提拔人才， 前人老慈悲指示周講師宏華領點傳師命，替師傳道。</p>
          <p>道務在穩定中逐漸宏展，每年固定三班法會，都能如期舉行，渡化眾生無數。又承蒙 前人老睿智領導，提倡『集體領導，整體帶動』，推行道場『忠義字班』、『十組分工運作』、『三界一元化』，使得『人人有事做，事事有人做』，各組廣納賢良，行功了愿，大家同心合作，皆希望能為道場盡心盡力，報答天恩師德於萬一。</p>
          <p>沾德這個大家庭，匯聚了來自五湖四海的同修兄弟姊妹們，雖然彼此處於不同的生長環境與文化背景，但修辦的精神和道心是同樣的堅定不移。無論是歷年佛堂各項活動或各項研習等，前賢們皆是相互邀約共同護持道場的各項慶典活動。同修之間如手足情誼，佛堂已經成為大家心中溫暖的依靠，讓漂泊的海外遊子有歸鄉的感覺。</p>
          <p>西元一九九九年的法會，活佛老師臨壇，慈悲指示成立道務中心，翌年西元二000年十月五日 前人老慈悲蒞臨舊金山，正式成立舊金山道務中心。致使舊金山的道務更上一層樓。</p>
          <p>因道務的需求，西元二00四年一月一日， 前人老慈悲賦予林慧玲講師領命點傳師，替師分擔重責大任。</p>
          <p>隨著發一崇德舊金山沾德佛堂道務逐漸宏展，道親人數愈來愈多，沾德佛堂一步一腳印與台灣大道場齊步走 同步行，在全體道親共同護持下，每逢週六分別開設國語、粵語、英語進修班以多元文化成全道親；尚有學界、青少年、小天使之課程。除此之外，佛堂另安排每個月一次壇主講師精進班課程，以端正修辦理念、感恩、懺悔、凝聚道氣。</p>
          <p>前人老於西元二00六年之前，進修班畢班後，親臨提拔人才，使人盡其才，才盡其職，職盡其能，能盡其用。</p>
          <p>為拓展東灣道務，周點傳師宏華於西元二00四年七月二十九日在Fremont 成立了另一間恆德公共佛堂，以渡化成全當地的眾多道親和有緣佛子。</p>
          <p>二00八年-兒童讀經班正式在美國舊金山開辦，希望藉由兒童讀經班的成立能廣結善緣進而引渡原胎佛子；親子讀經確實在東灣發揮了很大的效果。</p>
          <p>二00九年-陸續有講粵語的道親,促成了翌年粵語班的進修成立.</p>
          <p>二0一0年-粵語新民班從正式開班至目前，班員如今皆是講師培訓班班的成員，各個都積極參辦道場的道務運作，立了清口愿並成為壇主.</p>
          <p>在道親人數日漸增多的情況下，恆德佛堂六組正式運作，道親們都熱誠參與也很盡心，在學習中慢慢提昇且有進步，雖然無法做到事事圓滿、盡合人意，但上天取的是我們的那份真心。</p>
          <p>前人種樹後人乘涼，感謝天恩師德、白水聖帝、不休息菩薩聖德加披、點傳師慈悲及東灣壇主講師們的犠牲奉獻，才有今日的恆德佛堂，期間陸續又成立了五間家庭佛堂，未來的路縱使還有很多挑戰，但卻是希望無窮，只要眾生上法船，後學們能有機會了自己的愿，真是天恩師德報不完啊！</p>
          <p>於二0一二年5月24日找到現在恆德佛堂運作的商用建築物。眾道親們皆歡欣鼓舞，感謝上天慈悲撥轉！</p>
          <p>二00六年十二月九日，雷講師秀珊設立伍氏佛堂，並發心捨出此間佛堂，作為沾德佛堂發展南灣道務的根據地。其地區屬高科技地段，高級知識份子較多，相形之下道務推展要比其它地方困難度高些，因此，於二0一三年，夏經理樹華領命點傳師，並在南灣負責幫辦道務。迄今為止，伍氏佛堂由當地由幾位資深講師、壇主負責帶動，老幹新枝互相幫助，樹立典範，於2014年提昇為公共佛堂（同德壇）。</p>
          <p>小天使班，教授中華文化，孝經故事、古典文學等。兒童讀經班，積極推廣社區兒童讀經，弘揚儒家孝悌文化，綱常古禮，深受家長們的歡迎。為邁向 前人老提倡的『三界一元化』奠定了穩固堅實的基礎。</p>
          <p>記得在一次複習班，活佛老師臨壇慈悲對周點傳師說：『人才漸漸出來，妳就不會那麼辛苦了！』又有一次法會，南海古佛身邊的小玉女臨壇說：『我來看我的兄弟姊妹們，他們都降世來到這裡』。眾人喜出望外，無不讚嘆仙佛慈悲將二十年後的路舖好了。</p>
          <p>自一九九三年至今之開闡道務，造就了一批老中青三代壇主、講師，學界的菁英，更成為道場之棟梁。沾德佛堂這一路走來，風雨飄搖，依舊屹立不動，皆是蒙沾上天的恩典與垂憫，得受無數仙佛菩薩搭幫助道，更是恩承於活佛恩師聖德無疆、白水聖帝、不休息菩薩聖德在天庇佑，崇德負責群點傳師們的引領，前賢們的厚愛，才有舊金山沾德佛堂。</p>
          <p>崇慧佛院於二0一一年三月十五日正式安座。</p>
          <p>現今三曹普渡，印證了天人共辦，道真、理真、天命真的殊勝，</p>
          <p>發一崇德舊金山雖然沒有壯觀宏偉的佛殿，可是上天對我們的呵護並未減少；在投入修辦當中，才能體會何謂「天人共辦」；在修辦當中才更能體會何謂「天恩師德」。所謂「前人種樹，後人乘涼。」白水聖帝曾云：『每人辦一段』。但看我們如何為下一代做榜樣，將天道永昌，慧命永續傳承。</p>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}

export default History;