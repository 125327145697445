import React from 'react';
import TextField from '@mui/material/TextField';

const VegeSearch = props => {
  const { searchText, setSearchText } = props;

  const handleOnChange = event => {
    setSearchText(event.target.value);
  }

  return (
    <TextField 
      id="vegeSearch" 
      label="搜尋" 
      variant="standard" 
      value={searchText}
      onChange={handleOnChange}
    />
  )
}

export default VegeSearch;