const validateEmail = (email) => {
  if (email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/)) {
    return true;
  }
  return false;
}

const padNumToString = (num, digit) => {
  let val = num+'';
  if (val.length <= digit) {
    for (let i=0; i<(digit - val.length); i++) {
      val = '0'+val;
    }
  }
  return val;
}

export { 
  validateEmail,
  padNumToString
};