import React from 'react';
import Grid from '@mui/material/Grid';

import OneNews from './OneNews';

const DisplayNews = props => {
  const { news } = props;
  
  return (
    <Grid container spacing={2} justifyContent="center">
      {
        news.map((theNews, i) => {
          return (
            <Grid item xs={12} md={8}>
              <OneNews news={theNews} />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default DisplayNews;