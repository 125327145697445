import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Typography from '@mui/material/Typography';
import ImageGallery from "react-image-gallery";
import { Link } from 'react-router-dom';

import "react-image-gallery/styles/css/image-gallery.css";

import { getPhotoByPhotoId } from '../../util/apiUtils';

const LoadOneBlog = props => {
  const { id } = props;
  const [ photos, setPhotos ] = useState([]);
  const [ eventTitle, setEventTitle ] = useState('');
  const [ eventDate, setEventDate ] = useState('');
  const [ photoCount, setPhotoCount ] = useState(null);
  const [ showGallery, setShowGallery ] = useState(false);
  const [ images, setImagaes ] = useState([]);
  const [ startIndex, setStartIndex ] = useState(0);

  useEffect(() => {
    loadPhotos();
  }, []);

  const styles = {
    container: {
      marginTop: 30,
      marginBottom: 80
    },
    title: {
      color: '#999999',
      marginBottom: 20,
      textAlign: 'center',
      fontSize: '1.1em'
    },
    content: {
      // width: '100%',
      // maxHeight: 250
    },
    photoContainer: {
      padding: 5
    },
    photo: {
      width: '100%',
      padding: 5,
      border: '8px outset'
    },
    closeButton: {
      position: 'absolute',
      top: 40,
      right: 20,
      zIndex: 999,
      fontSize: '3.0em',
      cursor: 'pointer'
    },
    backButtonContainer: {
      marginTop: 30,
      textAlign: 'center'
    },
    backButton: {
      color: '#259200',
      borderColor: '#259200'
    }
  }

  const loadPhotos = _ => {
    const getPhotos = async() => {
      const result = await getPhotoByPhotoId({ photoId: id });
      const { title, date, count, photos} = result;
      
      if (Array.isArray(photos) && photos.length > 0) {
        setPhotos(photos);
        setEventDate(date);
        setEventTitle(title);
        setPhotoCount(count);
      }

      const allImages = photos.map(ph => {return { original: ph, thumbnail: ph }});
      setImagaes(allImages);
    }
    
    getPhotos();
  }

  const handleShowGallery = index => {
    setStartIndex(index);
    setShowGallery(true);
    window.scrollTo({ top: 80, behavior: 'smooth' });
  }

  const hideGallery = _ => {
    setStartIndex(0);
    setShowGallery(false);
  }

  return (
    <Grid 
      container
      direction='row'
      style={styles.container}
    >
      <Grid 
        item
        xs={12}
        style={{position: 'relative'}}
      >
        <Typography
          component="div"
          style={styles.title}
        >
          { eventTitle }

          {
            eventDate && (
              <div>
                日期： { eventDate }
              </div>  
            )
          }
          <div>~ { photoCount } ~</div>
        </Typography>

        {
          showGallery && (
            <div 
              style={styles.closeButton} 
              onClick={hideGallery}
            >
              <CloseTwoToneIcon 
                color="action"
                fontSize="large"
              />
            </div>
          )
        }
        
      </Grid>

      {
        showGallery && (
          <Grid
            item
            justifyContent="center"
            xs={12}
          >
            <ImageGallery 
              items={images} 
              showThumbnails={false}
              startIndex={startIndex}
            />
          </Grid>
        )
      }

      {
        !showGallery && (
          <Grid 
            item
            container
            xs={12}
            justifyContent="space-around"
          >
            {
              photos.map((photo, i) => {
                return (
                  <Grid
                    item
                    key={i}
                    xs={6}
                    md={3}
                    style={styles.photoContainer}
                    onClick={handleShowGallery.bind(this, i)}
                  > 
                    <img src={photo} style={styles.photo} alt={'photo'+i} />
                  </Grid>
                )
              })
            }
          </Grid>
        )
      }

      <Grid 
        item
        xs={12}
        justifyContent="center"
        style={styles.backButtonContainer}
      >
        <Link to='/blog'>
          <Button 
            variant="outlined"
            style={styles.backButton}
          >返回活動花絮</Button>
        </Link>
      </Grid>
    </Grid>
  )
}

export default LoadOneBlog;



// title,
//       date,
//       photoId,
//       photos, 
//       count: photos.length 