import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const StanDialog = props => {
  const { 
    open, 
    handleClose,
    dialog,      // title, description
    children,
    action
  } = props;

  const styles = {
    content: {
      textAlign: 'center',
      width: '100%'
    }
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={dialog.title}
        aria-describedby={dialog.description}
        fullWidth={true}
      >
        <DialogTitle id={dialog.title} style={{textAlign: 'center'}}>
          {dialog.title}
        </DialogTitle>

        <DialogContent style={styles.content}>
          { children }
        </DialogContent>

        { action && (
          <DialogActions>
            <Button onClick={handleClose}>{ action.no }</Button>
            <Button onClick={handleClose} autoFocus>{ action.yes }</Button>
          </DialogActions>
        )}
        
      </Dialog>
  )
}

export default StanDialog;