import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MiddleSchoolHeader from './Classes/MiddleSchoolHeader';
import ClassesNavigation from './Classes/ClassesNavigation';

const MiddleSchool = props => {
  const styles = {
    title: {
      width: '100%',
      textAlign: 'center'
    },
    classSchedule: {
      marginTop: 30
    }
  }

  return (
    <Grid
      container
      direction='row'
    >
      <Grid
        item
        xs={12}
      >
        <MiddleSchoolHeader />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <ClassesNavigation />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h4"
          component="div"
          style={styles.title}
        >
          學界簡介
        </Typography>
      </Grid>

      <Grid item xs={1} md={2}></Grid>
      <Grid
        item
        xs={10}
        md={8}
      >
        <Typography
          component="div"
        >
          <p>
            晨曦，帶給地球第一道曙光；青春，歌頌人生最美的時光。青少年，宛如紅日噴薄而出，光芒萬丈，照射四方，帶給大地無限生機；恰如學界的莘莘學子們，代表著大道場未來發展的無限希望。因此，如何帶動學界青少年專心投入道場各項聖事和活動已經成為至關重要的目標規劃。有別於社會界的傳統上課方式，學界不同年齡層帶動方式亦是生動、活潑、多樣化。【光慧文教基金會】在領導前人陳大姑（不休息菩薩）以祂老在世時的睿智提倡：將課程和活動分為社會界、學界和青少年界(小天使) 三界來運作, 讓老、中、青、少都能以「因材施教」的方式來照顧和成長, 並且加以培育造就人才，落實道場教育，彌補家庭教育、學校教育、社會教育之不足，以固有文化道德為根本。
          </p>
          <p>
            學界每週六上午開班一次，負責學界的學長和學姐們透過活潑、互動的教學方式，教導青少年孝悌之道、人際關係、生活禮儀、養生之道與佛規禮節等不同單元的課題。這些課程皆是以中英雙語的方式來進行, 希望這些第二代都可以借此學習中文同時也能體會到掌握中文的重要性。年輕的班員們從剛開始的陌生、抵觸到漸漸發現來上課和參加活動不僅可以結識好朋友，同時也可以學習到許多從未接觸過的中華傳統文化。比如在【中元普渡】的之際，他們學會思念祖先的恩德、慎終追遠的意義。青少年也漸漸地了解素食的選擇能讓身體更健康以及愛護動物、了斷因果關係的必要性，甚至會主動詢問講師們如何自己料理素食養生。
          </p>
          <p>
            多年來，學界在暑假期間皆會舉辦『成長營』。主題包括「感恩體驗營」，為了能讓每位青少年體會母親懷他們時的辛苦，林點傳師與負責的學長學姐們連夜馬不停蹄地製作十五磅的沙包，綁在青少年身上。一天的活動下來，他們都能體會到母親的偉大。這堂【孝道】課程最主要是提醒每位班員要孝順、體貼父母心。台灣學界每年暑假都會舉辦【4Q快樂成長營】。負責的學長學姐們希望能帶給遠在舊金山的這些年輕人同樣的體驗和學習機會，所以在二零一零年暑假學習舉辦「4Q成長營」。透過此成長營來提升學生的IQ (智力商數), AQ (逆境商數), MQ (道德商數), 還有EQ (情緒商數) 。這場活動結束後，青少年們還依依不捨，流連忘返。
          </p>
          <p>
            二零一 一年，學界成立了【蘋果志工隊】：A.P.P.L.E.S. Volunteer Program。希望青少年們能藉由做志工來體驗『助人為快樂之本』之宗旨，使其了解到這個世界上尚有許多需要幫助的人們， 而即使能力有限也有他們力所能及之事。不僅如此，參加【蘋果志工隊】亦能以他們所獲得的志工時數來滿足他們學業上的需求。
          </p>
          <p>
            【舊金山光慧文教基金會】之學界目前在灣區有三個運作地點：三藩市日落區；東灣聖羅倫索市(San Lorenzo)，和南灣山景城 (Mountain View)，以方便學員們就近學習。遇到大型活動和志工隊出隊時，負責的學長學姐們會儘量把三個地點的學員們聚集在一起，希望學員們能培養團結合作的精神同時也能增進彼此之間的道情、友誼。
          </p>
        </Typography>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  )
}

export default MiddleSchool;