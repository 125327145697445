import React, { useState } from 'react';

const UserContext = React.createContext();

const UserProvider = props => {
  const [ user, setUser ] = useState({});

  const userData = {
    userData: [ user, setUser ]
  }

  return (
    <UserContext.Provider value={userData}>
      { props.children }
    </UserContext.Provider>
  )
}

export default UserProvider;
export {
  UserContext
}