import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const VegeHeader = props => {
  const styles = {
    titleBlock: {
      maxHeight: 350,
      overflow: 'hidden'
    },
    vegeImage: {
      width: '100%'
    },
    vegeDescription: {
      padding: 20
    }
  };

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={styles.titleBlock}
      >
        <img style={styles.vegeImage} src='/img/vege/vege-main.jpg' alt='vege' />
        <Typography
          variant="h4"
          component="div"
          sx={{ 
            position: 'absolute', 
            color: '#ffffff', 
            fontSize: '4.0em', 
            top: { xs: 100, sm: 150 }, 
            left: { xs: 100, md: 200 } 
          }}
        >
          素<br/>食
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ 
          backgroundColor: 'rgba(230, 225, 225, 0.3)'
        }}
      >
        <Typography
          component="div"
          style={styles.vegeDescription}
        >
          老舊的觀念認為吃素是在吃苦齋，其實現代吃素是在吃樂齋喔! 現在蔬食飲食早已是世界上的新潮流也被專家研究肯定不只讓我們身體健康也幫助地球的環保生態。所謂 "天有好生之德，是以仁慈為本，仁即是命之根，德之本也。" 吃素還可以幫助我們修身養性，培養慈悲心。可見素食會讓我們的身心靈一起健康快樂起來!
        </Typography>
      </Grid>
    </React.Fragment>
  )
}

export default VegeHeader;