import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CakeIcon from '@mui/icons-material/Cake';

import VegeTypeSelection from './VegeTypeSelection';
import VegeSearch from './VegeSearch';
import VegeDishes from './VegeDishes';
import { getRecipesBySearchTextAndType } from '../../util/apiUtils';

const initialCount = 9;
const subsequentCount = 6;

const VegeContent = props => {
  const [ type, setType ] = useState(null);
  const [ searchText, setSearchText ] = useState('');
  const [ allRecipes, setAllRecipes ] = useState([]);
  const [ totalCount, setTotalCount ] = useState(0);
  const [ recipes, setRecipes ] = useState([]);
  const [ showMore, setShowMore ] = useState(false);

  useEffect(()=> {
    const getRecipes = async() => {
      const result = await getRecipesBySearchTextAndType({searchText, type});
      const allRecipes = result.recipes;
      if (Array.isArray(allRecipes)) {
        setAllRecipes(allRecipes);
        setTotalCount(allRecipes.length);
        const lastCount = allRecipes.length >= initialCount ? initialCount : allRecipes.length;
        setRecipes(allRecipes.slice(0, lastCount));
        if (allRecipes.length > initialCount) {
          setShowMore(true);
        }
      }
    }
    
    getRecipes();
  }, [searchText, type]);

  const styles = {
    mainContainer: {
      marginTop: 20
    },
    typeSelection: {
      textAlign: 'right',
      paddingRight: 20
    },
    vegeSearch: {
      paddingLeft: 20
    },
    recipeContainer: {
      padding: 20
    },
    recipe: {
      marginBottom: 20
    },
    noRecipe: {
      marginTop: 50
    },
    showMoreButton: {
      color: '#259200',
      borderColor: '#259200'
    }
  }

  const handleShowMoreRecipes = _ => {
    const currentCount = recipes.length;
    let lastCount = currentCount + subsequentCount;
    if (totalCount < lastCount) {
      lastCount = totalCount;
      setShowMore(false);
    }
    setRecipes(allRecipes.slice(0, lastCount));
  }

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      style={styles.mainContainer}
    >
      <Grid
        item
        xs={6}
        style={styles.typeSelection}
      >
        <VegeTypeSelection
          setType={setType}
          type={type}
        />
      </Grid>

      <Grid
        item
        xs={6}
        style={styles.vegeSearch}
      >
        <VegeSearch 
          searchText={searchText}
          setSearchText={setSearchText} 
        />
      </Grid>

      <Grid
        item
        container
        xs={12}
        justifyContent='space-around'
        spacing={2}
        style={styles.recipeContainer}
      >
        <VegeDishes recipes={recipes} />

        {
          recipes.length === 0 && (
            <Typography
              component="div"
              style={styles.noRecipe}
            >
              <CakeIcon sx={{ fontSize: 150 }} />
            </Typography>
          )
        }

        {
          showMore && (
            <Button 
              variant="outlined"
              onClick={handleShowMoreRecipes}
              style={styles.showMoreButton}
            >顯示更多食譜</Button>
          )
        }
      </Grid>
    </Grid>
  )
}

export default VegeContent;