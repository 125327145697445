const { loginWithGoogle } = require('./apiUtils');

const handleGoogleLogin = async ({ token }) => {
  const response = await loginWithGoogle({ token });

  if (response.user) {
    const { jwt } = response.user;
    window.localStorage.setItem('token', jwt);
    return response.user;
  }

  return null;
}

const logoutUser = _ => {
  window.localStorage.removeItem('token');
}

export {
  handleGoogleLogin,
  logoutUser
}