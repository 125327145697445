import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LittleAngelHeader from './Classes/LittleAngelHeader';
import ClassesNavigation from './Classes/ClassesNavigation';
import LittleAngelClassSchedule from './Classes/LittleAngelClassSchedule';

const LittleAngel = props => {
  const styles = {
    title: {
      width: '100%',
      textAlign: 'center'
    },
    classSchedule: {
      marginTop: 30
    }
  }

  return (
    <Grid
      container
      direction='row'
    >
      <Grid
        item
        xs={12}
      >
        <LittleAngelHeader />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <ClassesNavigation />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h4"
          component="div"
          style={styles.title}
        >
          小天使班簡介
        </Typography>
      </Grid>

      <Grid item xs={1} md={2}></Grid>
      <Grid
        item
        xs={10}
        md={8}
      >
        <Typography
          component="div"
        >
          <p>
            小天使班於2012年開始運作。課程時段劃分為學年度及暑期兩部份，以每週一節課方式進行。課程教材以「發一崇德青少年界小天使班進修班教學手冊」及自行研發之教案為題材， 動靜結合，課程內容豐富及多元化教學。設班是為了培養小天使崇尚綱常倫理、尊師重道，建立良好的生活態度及習慣。
          </p>
          <p>
            「不休息菩薩」慈訓：『以整體領導、整體帶動、三界一元化的運作模式、推動三界的道務，使老、中、青、少，人才輩出；在和諧、合作、和平的氣氛下，使道場邁向標準。』因而，希望從小就能打好基礎，培育道場幼苗，造就出文質彬彬、品學兼優、人格高尚與風度翩翩的正人君子。讓道場教育往下紮根，培育道場幼苗成為日後的棟樑人才，讓道場慧命永續傳承。
            </p>
        </Typography>
      </Grid>
      <Grid item xs={1} md={2}></Grid>

      <Grid
        item
        xs={12}
        style={styles.classSchedule}
      >
        <LittleAngelClassSchedule />
      </Grid>
    </Grid>
  )
}

export default LittleAngel;