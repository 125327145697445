import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';

const ClassesNavigation = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [ openLittleAngel, setOpenLittleAngel ] = useState(false);
  const [ openMiddleSchool, setOpenMiddleSchool ] = useState(false);
  const [ openAdultClass, setOpenAdultClass ] = useState(false);

  const styles = {
    button: {
      width: 200,
      margin: '30px 10px',
      color: '#259200',
      borderColor: '#259200'
    },
    navLink: {
      textDecoration: 'none', 
      color: '#000000'
    }
  }

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
    switch(event.currentTarget.id) {
      case 'littleAngel': {
        setOpenLittleAngel(true); break;
      }
      case 'middleSchool': {
        setOpenMiddleSchool(true); break;
      }
      case 'adultClass': {
        setOpenAdultClass(true); break;
      }
      default: {
        
      }
    }
  }

  const handleMenuClose = event => {
    setAnchorEl(null);
    setOpenLittleAngel(false);
    setOpenMiddleSchool(false);
    setOpenAdultClass(false);
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
    >
      <Button 
        style={styles.button} 
        size='large' 
        variant="outlined"
        id='littleAngel'
        onClick={handleMenuClick}
      >
        小天使班
      </Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openLittleAngel}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NavLink to='/littleangel' style={styles.navLink} >
          <MenuItem onClick={handleMenuClose}>簡介</MenuItem>
        </NavLink>
        <NavLink to='/dujing' style={styles.navLink}>
          <MenuItem onClick={handleMenuClose}>親子兒童讀經班</MenuItem>
        </NavLink>
        <NavLink to='/verylittleangel' style={styles.navLink}>
          <MenuItem onClick={handleMenuClose}>幼幼唐詩唱遊班</MenuItem>
        </NavLink>
      </Menu>


      <Button 
        style={styles.button} 
        onClick={handleMenuClick} 
        size='large' 
        variant="outlined"
        id='middleSchool'
      >學界</Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openMiddleSchool}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NavLink to='/middleschool' style={styles.navLink} >
          <MenuItem onClick={handleMenuClose}>簡介</MenuItem>
        </NavLink>
        <NavLink to='/middleschool/lectures' style={styles.navLink}>
          <MenuItem onClick={handleMenuClose}>學界進修班</MenuItem>
        </NavLink>
      </Menu>



      <Button 
        style={styles.button} 
        onClick={handleMenuClick} 
        size='large' 
        variant="outlined"
        id='adultClass'
      >社會界</Button>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={openAdultClass}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <NavLink to='/adultclass' style={styles.navLink} >
          <MenuItem onClick={handleMenuClose}>簡介</MenuItem>
        </NavLink>
        <NavLink to='/adultclass/lectures' style={styles.navLink}>
          <MenuItem onClick={handleMenuClose}>社會界進修班</MenuItem>
        </NavLink>
      </Menu>
      
    </Grid>
  )
}

export default ClassesNavigation;