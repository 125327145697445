import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { days } from '../../util/constants';

const ClassSchedule = props => {
  const { theClass } = props;

  const styles = {
    classSchedule: {
      padding: 40,
      backgroundColor: '#f5f5f5'
    }
  }
  
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      style={ styles.classSchedule }
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h6"
          component="div"
        >
          { theClass.area }
        </Typography>
      </Grid>

      <Grid
        item
      >
        <p>
          時間： 星期{ days[theClass.day] } { theClass.startTime } - { theClass.endTime }
        </p>
        <p>
          地點： { theClass.location }
        </p>
      </Grid>

    </Grid>
  )
}

export default ClassSchedule;