import { getCalendarListByYearAndMonth } from './apiUtils';
import { padNumToString } from './utils';

const getCalendarList = async ({month, year}) => {
  if (isValidDate({ type: 'year', val: year}) && isValidDate({ type: 'month', val: month })) {
    const calendarList = await getCalendarListByYearAndMonth({ month, year});
    return calendarList;
  } else {
    throw new Error('Invalid month or year');
  }
}

const isValidDate = ({ val, type }) => {
  if (type === 'year') {
    return (val > 1990 && val < 2060);
  }
  if (type === 'month') {
    return (val >= 1 && val <= 12);
  }
}

const parseNewsDate = val => {
  const theDate = new Date(val);
  return theDate.toLocaleDateString('en-US');
}

const parseNewsTime = news => {
  const startTime = news.start.dateTime;
  const endTime = news.end.dateTime;
  let final = '';

  if (startTime) {
    const start = new Date(startTime);
    const hour = start.getHours() > 12 ? (start.getHours()-12) : start.getHours()
    final = getAmPm(start.getHours()) + ' ' + padNumToString(hour,2) + ':' + padNumToString(start.getMinutes(),2);
  }
  if (endTime) {
    const end = new Date(endTime);
    const hour = end.getHours() > 12 ? (end.getHours()-12) : end.getHours()
    final += ' - ' + getAmPm(end.getHours()) + ' ' + padNumToString(hour,2) + ':' + padNumToString(end.getMinutes(),2);
  }

  return final;
}

const getAmPm = val => {
  if (val > 12) {
    return '下午'; //'pm'
  }
  return '上午'; //'am';
}

export {
  getCalendarList,
  parseNewsDate,
  parseNewsTime
}