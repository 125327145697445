const FYCD_API_HOSTNAME_PROD = 'fycdapi.fycd.us';
const FYCD_API_HOSTNAME_LOCAL = 'localhost';

const FYCD_GOOGLE_API_KEY = '474209139818-vu0k2sj9ko1p39bavld2hterl9nlj61v.apps.googleusercontent.com';

const RECAPTCHA_SITE_KEY_LOCAL = '6LcjJSYTAAAAACQh8Bd208naNIKUpR4eyas-LaBQ';
const RECAPTCHA_SITE_KEY_PROD = '6LdAuikTAAAAAKuEznZDWFUi3CT4YrxYr31LGDnK';

const DEFAULT_NUMBER_OF_EVENT = 6;

const NUMBER_OF_EVENTS_TO_LOAD = 3;
const INITIAL_NUMBER_OF_EVENTS = 6;

const navItems = ['關於我們', '最新消息', '活動花絮', '道的見證', '進修天地', '素食廣場'];
const pageLinks = ['/about', '/news', '/blog', '/experience', '/classes', '/vege'];

const menuDrawerWidth = 280;

const numOfExperienceToDisplay = 3;

const days = [ '日', '一', '二', '三', '四', '五', '六', '日' ];

const months = ['0', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];

const littleAngelClassSchedule = [
  {
    area: '舊金山',
    day: 6,
    startTime: '7.45am',
    endTime: '9.30am',
    location: '崇慧佛院'
  },
  {
    area: '東灣',
    day: 7,
    startTime: '11.30am',
    endTime: '12.30pm',
    location: '恆德佛堂'
  },
  {
    area: '南灣',
    day: 7,
    startTime: '10.30am',
    endTime: '12.00pm',
    location: '同德佛堂'
  }
];

const duJingClassSchedule = [
  {
    area: '舊金山',
    day: 6,
    startTime: '9.00am',
    endTime: '12.00am',
    location: '崇慧佛院'
  },
  {
    area: '東灣',
    day: 7,
    startTime: '9.15am',
    endTime: '12.30pm',
    location: '恆德佛堂'
  }
];

const veryLittleAngelClassSchedule = [
  {
    area: '東灣',
    day: 7,
    startTime: '10.00am',
    endTime: '10.30pm',
    location: '恆德佛堂'
  },
  {
    area: 'San Leandro',
    day: 1,
    startTime: '10.00am',
    endTime: '10.40pm',
    location: '明德佛堂'
  },
  {
    area: 'San Leandro',
    day: 7,
    startTime: '10.00am',
    endTime: '10.40pm',
    location: '明德佛堂'
  }
];

module.exports = {
  FYCD_API_HOSTNAME_PROD,
  FYCD_API_HOSTNAME_LOCAL,
  FYCD_GOOGLE_API_KEY,
  RECAPTCHA_SITE_KEY_LOCAL,
  RECAPTCHA_SITE_KEY_PROD,
  DEFAULT_NUMBER_OF_EVENT,
  NUMBER_OF_EVENTS_TO_LOAD,
  INITIAL_NUMBER_OF_EVENTS,
  navItems,
  pageLinks,
  menuDrawerWidth,
  numOfExperienceToDisplay,

  days,
  months,

  littleAngelClassSchedule,
  duJingClassSchedule,
  veryLittleAngelClassSchedule
}