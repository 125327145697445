import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ReCAPTCHA from "react-google-recaptcha";

import { getRecaptchaSiteKey, saveContactUs } from '../util/apiUtils';
import { validateEmail } from '../util/utils';

const ContactUs = props => {
  const [ firstname, setFirstname ] = useState('');
  const [ lastname, setLastname ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ texts, setTexts ] = useState('');
  const [ recaptchaReady, setRecaptchaReady ] = useState(false);
  const [ evaluate, setEvaluate ] = useState(false);
  const [ showResult, setShowResult ] = useState(false);
  const [ result, setResult ] = useState('');

  const styles = {
    title: {
      marginTop: 40,
      textAlign: 'center'
    },
    description: {
      marginTop: 50,
      marginBottom: 20
    },
    input: {
      width: '100%',
      textAlign: 'center',
      marginTop:10
    },
    inputText: {
      minWidth: 300
    },
    button: {
      marginTop: 20,
      marginBottom: 40,
      color: '#259200',
      borderColor: '#259200',
      cursor: 'pointer'
    },
    result: {
      textAlign: 'center' 
    }
  }

  const handleFieldChange = event => {
    const { id, value } = event.target;
    switch(id) {
      case 'firstname': {
        setFirstname(value);
        break;
      }
      case 'lastname': {
        setLastname(value);
        break;
      }
      case 'email': {
        setEmail(value);
        break;
      }
      case 'texts': {
        setTexts(value);
        break;
      }
      default: {}
    }
  }

  const recaptchaOnChange = value => {
    if (value) {
      setRecaptchaReady(true);
    }
  }

  const submitForm = async event => {
    if (firstname && email && texts) {
      setEvaluate(false);
      const data = { 
        firstName: firstname, 
        lastName: lastname,
        email, texts
      };

      const response = await saveContactUs({data});
      let theResult = '';

      if (response.errors) {
        theResult = (
          <Alert severity="error">
            <Typography
              variant='h6'
              component="div"
              style={styles.result}
            >
              對不起，我們暫時無法處理。請稍候再試。
            </Typography>
          </Alert>
        );
      } else {
        theResult = (
          <Alert severity="info">
            <Typography
              variant='h6'
              component="div"
              style={styles.result}
            >
              感謝您給我們留言。我們會儘快回覆。
            </Typography>
          </Alert>
        );
        // clear all values
        setFirstname('');
        setLastname('');
        setEmail('');
        setTexts('');
        setEvaluate(false);
      }

      setResult(theResult);
      setShowResult(true);
    } else {
      setEvaluate(true);
    }
  }

  const checkEmail = email => {
    return (email.trim().length > 0) && validateEmail(email);
  }

  const handleCloseResult = _ => {
    setShowResult(false);
  }

  return (
    <Grid
      container
      direction='row's
      justifyContent='center'
    >
      <Grid item xs={12}>
        <Typography
          variant='h4'
          component="div"
          style={styles.title}
        >
          聯絡我們
        </Typography>
      </Grid>

      <Grid item xs={2} md={3}></Grid>
      <Grid
        item
        xs={8}
        md={6}
      >
        <Typography
          component="div"
          style={styles.description}
        >
          佛云：【道者理也，不明理，焉修道。故欲修道，必先明理。明理之法無他，全在有疑必問而已。】感謝您瀏覽沾德網頁！若有任何疑問，歡迎您及時聯絡我們。請輸入您的聯絡方式，我們將盡快解答您的疑問。
        </Typography>
      </Grid>
      <Grid item xs={2} md={3}></Grid>

      <Grid
        item
        container
        xs={12}
        justifyContent='center'
      >
        <Grid item xs={12} style={styles.input}>
          <TextField 
            required
            id="firstname" 
            label="名字" 
            variant="filled" 
            value={firstname}
            style={styles.inputText} 
            onChange={handleFieldChange}
            error={evaluate ? firstname.trim().length<1 : false}
          />
        </Grid>
        <Grid item xs={12} style={styles.input}>
          <TextField 
            id="lastname" 
            label="姓氏" 
            variant="filled"  
            value={lastname}
            style={styles.inputText}
            onChange={handleFieldChange}
            error={evaluate ? lastname.trim().length<1 : false}
          />
        </Grid>
        <Grid item xs={12} style={styles.input}>
          <TextField 
            required
            id="email" 
            label="電子郵件地址" 
            variant="filled"  
            value={email}
            style={styles.inputText} 
            onChange={handleFieldChange}
            error={evaluate ? !checkEmail(email) : false}
          />
        </Grid>
        <Grid item xs={12} style={styles.input}>
          <TextField 
            required
            id="texts" 
            label="您的問題或建議" 
            rows={4} 
            multiline 
            variant="filled"  
            value={texts}
            style={styles.inputText} 
            onChange={handleFieldChange}
            error={evaluate ? texts.trim().length<1 : false}
          />
        </Grid>
        <Grid item xs={2} sm={3} md={4} lg={5}></Grid>
        <Grid item xs={8} sm={6} md={4} lg={2} style={styles.input}>
          <ReCAPTCHA
            sitekey={getRecaptchaSiteKey()}
            onChange={recaptchaOnChange}
          />
        </Grid>
        <Grid item xs={2} sm={3} md={4} lg={5}></Grid>

        <Button 
          variant="outlined" 
          style={styles.button}
          disabled={!recaptchaReady}
          onClick={submitForm}
        >寄出</Button>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showResult}
        onClose={handleCloseResult}
        message={result}
      />
    </Grid>
  )
}

export default ContactUs;