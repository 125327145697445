import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const VegeTypeSelection = props => {
  const { type, setType } = props;

  const styles = {
    selectionBox: {
      minWidth: 180
    }
  }

  const handleTypeSelection = (event) => {
    if (event.target.value > 0) {
      setType(event.target.value);
    } else {
      setType(null);
    }
  };

  return (
    <FormControl variant="standard" sx={styles.selectionBox}>
        <InputLabel id="select-type-label">類別</InputLabel>
        <Select
          labelId="select-type"
          id="select-type"
          value={type}
          onChange={handleTypeSelection}
          label="類別"
        >
          <MenuItem value={0}>點選類別</MenuItem>
          <MenuItem value={1}>主菜</MenuItem>
          <MenuItem value={2}>甜點</MenuItem>
          <MenuItem value={3}>湯面類</MenuItem>
          <MenuItem value={4}>粥類</MenuItem>
        </Select>
      </FormControl>
  )
}

export default VegeTypeSelection;