import React from 'react';
// import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageListItem from '@mui/material/ImageListItem';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';

const VegeDishes = props => {
  const { recipes } = props;

  const styles = {
    dishContainer: {
      overflow: 'hidden'
    },
    img: {
      minHeight: 200,
      maxHeight: 250
    }
  }

  return (
    <ImageList cols={3} gap={20} sx={{ width: '100%' }} >
      {
        recipes.map(({ id, image, title, like }) => (
          <Link to={`/vege/${id}`}>
            <ImageListItem key={title}>
              <img
                style={styles.img}
                src={image}
                srcSet={image}
                alt={title}
              />
              <ImageListItemBar
                title={title}
              />
            </ImageListItem>
          </Link>
        ))
      }
    </ImageList>
  )
}

export default VegeDishes;