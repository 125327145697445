import React from 'react';
import Grid from '@mui/material/Grid';

import { parseNewsDate, parseNewsTime } from '../../util/calendarUtil';

const OneNews = props => {
  const { news } = props;

  const styles = {
    container: {
      padding: 10,
      marginBottom: 10
    },
    theDate: {
      textAlign: 'right',
      alignSelf: 'center',
      borderRight: '1px solid gray',
      paddingRight: 20
    },
    content: {
      textAlign: 'left',
    }
  }

  return (
    <Grid container spacing={2} style={styles.container}>
      <Grid item xs={5} style={styles.theDate}>
        { parseNewsDate(news.start.date || news.start.dateTime) }
      </Grid>
      <Grid item xs={7} style={styles.content}>
        { news.summary } <br />
        { parseNewsTime(news) }
      </Grid>
    </Grid>
  )
}

export default OneNews;