import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

import { months } from '../../util/constants';

const NewsCalendar = props => {
  const { selectedYear, setYear, selectedMonth, setMonth } = props;
  const [ yearOffset, setYearOffset ] = useState(5);

  const styles = {
    calendar: {
      width: '100%',
      backgroundColor: '#f2f3e3',
      paddingTop: 5,
      paddingBottom: 5
    },
    title: {
      minWidth: 100
    },
    yearsContainer: {
      padding: 3,
      backgroundColor: '#ffffff'
    },
    yearsContainerSx: {
      maxWidth: {xs: '100%', md: '80%', lg: '60%'}
    },
    years: {
      overflowX: 'hidden'
    },
    monthsContainer: {
      backgroundColor: '#f2f3e3',
      overflowX: 'hidden',
      width: '100%',
      marginTop: 5
    },
    monthsItem: {
      padding: 3,
      backgroundColor: '#ffffff',
      width: 'auto'
    },
    year: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 1,
      paddingBottom: 1
    },
    month: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 1,
      paddingBottom: 1,
      whiteSpace: 'nowrap'
    },
    selectedStyle: {
      border: '2px solid black', color: '#ff0000'
    }
  }

  const getYears = _ => {
    const thisYear = (new Date()).getFullYear();
    const years = [];

    for (let i=0; i<12; i++) {
      years.push(thisYear-yearOffset+i);
    }
    return years;
  }

  const handleSetYear = year => {
    setYear(year);
  }

  const handleSetMonth = month => {
    setMonth(month);
  }

  const handleMonthOffset = back => {
    if (back) {
      setYearOffset(yearOffset-1);
    } else {
      setYearOffset(yearOffset+1);
    }
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      style={styles.calendar}
    >
      <Grid
        item
        style={styles.yearsContainer}
        container
        direction='row'
        justifyContent='center'
        wrap='nowrap'
        sx={styles.yearsContainerSx}
      >
        <Grid
          item
          xs
        >
          <ArrowCircleLeftOutlinedIcon
            onClick={handleMonthOffset.bind(this, false)}
          />
        </Grid>

        <Grid 
          item 
          style={styles.years}
        >
          { 
            getYears().map((year, i) => {
              let selectedStyle = {};
              if (year === selectedYear) {
                selectedStyle = styles.selectedStyle
              }

              return (
                <Typography
                  component="span"
                  key={i}
                  style={{...styles.year, ...selectedStyle}}
                  onClick={handleSetYear.bind(this, year)}
                >
                  {year}
                </Typography>
              )
            }) 
          }
        </Grid>

        <Grid
          item
          xs
        >
          <ArrowCircleRightOutlinedIcon
            onClick={handleMonthOffset.bind(this, true)}
          />
        </Grid>
      </Grid>

      <Grid
        item
        style={styles.monthsContainer}
        container
        justifyContent='center'
      >
        <Grid item
          style={styles.monthsItem}
        >
          { 
            months.slice(1).map((month, i) => {
              let selectedStyle = {};
              if ((i+1) === selectedMonth) {
                selectedStyle = styles.selectedStyle
              }

              return (
                <span
                  component="span"
                  key={i}
                  style={{...styles.month, ...selectedStyle}}
                  onClick={handleSetMonth.bind(this, (i+1))}
                >
                  {month}月
                </span>
              )
            }) 
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default NewsCalendar;