import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ClassSchedule from './ClassSchedule';
import { littleAngelClassSchedule } from '../../util/constants';

const LittleAngelClassSchedule = props => {
  const styles = {
    title: {
      marginTop: 20,
      textAlign: 'center',
      marginBottom: 10
    },
    littleAngelClassSchedule: {
      marginBottom: 20,
      textAlign: 'center'
    }
  }

  return (
    <Grid
      constainer
      direction='row'
      justifyContent='center'
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h5"
          component="div"
          style={styles.title}
        >
          小天使班
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={styles.littleAngelClassSchedule}
      >
        <Grid
          container
          direction='row'
          justifyContent='center'
        >
          {
            littleAngelClassSchedule.map((theClass, i) => {
              return (
                <React.Fragment>
                  <Grid item xs={1} sm={2}></Grid>
                  <Grid
                    item
                    xs={10}
                    sm={8}
                    style={styles.littleAngelClassSchedule}
                  >
                    <ClassSchedule theClass={theClass} />
                  </Grid>
                  <Grid item xs={1} sm={2}></Grid>
                </React.Fragment>
              )
            })
          }
        </Grid>
      </Grid>
      
    </Grid>
  )
}

export default LittleAngelClassSchedule;