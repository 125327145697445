import React from 'react';
import Box from '@mui/material/Box';

const HomeImage = props => {
  return (
    <Box component="main" sx={{ p: 3, padding: 0, position: { sm: 'relative' }, top: {xs: 0, sm: -30} }}>
      <img 
        src='/img/home/home.jpg' 
        style={{ width: '100%', margin: 0}}
        alt="FYCD logo"
      />
    </Box>
  )
}

export default HomeImage;