import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { getRandomEscriptures } from '../../util/apiUtils';

const EscriptureDocs = props => {
  const [ escriptures, setEscriptures ] = useState([]);

  useEffect(() => {
    const getEscriptures = async() => {
      const result = await getRandomEscriptures();
      const escriptures = result.escriptures;
      if (Array.isArray(escriptures) && escriptures.length > 0) {
        setEscriptures(escriptures);
      }
    }
    
    getEscriptures();
  }, []);

  const styles = {
    container: {
      borderBottom: 'dashed #CCCCCC 1px',
      width: '100%'
    },
    title: {
      marginBottom: 15,
      color: '#b2b2b2'
    },
    content: {
      marginTop: 10,
      marginBottom: 10,
      overflow: 'hidden',
      width: '100%'
    },
    link: {
      textDecoration: 'none', 
      color: '#000000'
    },
    linkDate: {
      fontSize: '0.8em',
      paddingLeft: 10,
      color: '#6B6B6B'
    }
  }

  const formatDate = inputDate => {
    try {
      const date = Date.parse(inputDate);
      const theDate = new Date(date);
      return theDate.getFullYear()+'年'+(theDate.getMonth()+1)+'月'+theDate.getDate()+'日';
    } catch (e) {
      return '';
    }
  }

  return (
    <Grid 
      container
      direction='column'
      style={styles.container}
    >
      <Grid 
        item
        style={styles.container}
        xs={12}
      >
        <Typography
          variant="h4"
          component="div"
          style={styles.title}
          sx={{ textAlign: { xs: 'center', md: 'left'}}}
        >
          仙佛慈訓
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: { xs: 'center', md: 'left'}}}>
        {
          escriptures.map((script, i) => {
            return (
              <Typography
                component="div"
                style={styles.content}
                key={i}
                noWrap
                sx={{ textAlign: { xs: 'center', md: 'left'}}}
              >
                <Link 
                  to={`/escriptures?link=${script.id}`}
                  style={styles.link}
                >
                  <span>{script.title}</span>
                  <span style={styles.linkDate}>{ formatDate(script.edited) }</span>
                </Link>
              </Typography>
            )
          })
        }
      </Grid>
    </Grid>
  )
}

export default EscriptureDocs;