import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useParams, useNavigate } from 'react-router-dom';

import { getRecipesById } from '../../util/apiUtils';

const VegeById = props => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ recipe, sestRecipe ] = useState({});
  const [ html, setHtml ] = useState('');

  useEffect(() => {
    const getHtml = async (url) => {
      fetch(url)
      .then((response) => {
        const body = response.text();
        return body;
      })
      .then((data) => {
        setHtml(data);
      })
    }
    const getRecipes = async() => {
      const result = await getRecipesById({id});
      const theRecipe = result.recipe;
      sestRecipe(theRecipe);
      getHtml(theRecipe.recipePage);
    }
    
    getRecipes();
  }, [id]);

  const styles = {
    vegeImageContainer: {
      marginTop: 50,
      textAlign: 'center'
    },
    vegeImage: {
      minWidth: 300,
      maxWidth: 500
    },
    vegeContent: {
      marginTop: 30
    },
    backButton: {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      cursor: 'pointer',
    }
  }

  const handleBackButton = _ => {
    navigate(-1);
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
    >
      <Grid
        item
        xs={12}
        style={styles.vegeImageContainer}
      >
        <img style={styles.vegeImage} src={recipe.image} alt={recipe.title} />
      </Grid>

      <Grid item sm={2} md={3} xs={1} />
      <Grid
        item
        sm={10}
        md={6}
        xs={10}
      >
        <div style={styles.vegeContent} dangerouslySetInnerHTML={{__html: html}} />
      </Grid>
      <Grid item sm={2} md={3} xs={1} />

      <Grid
        item
        xs={12}
        style={styles.backButton}
      >
        <ArrowBackIcon onClick={handleBackButton} />
      </Grid>
    </Grid>
  )
}

export default VegeById;