import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/login',
  children,
  token
}) => {
  console.log('----location:', window.location.href, window.location.pathname);
  if (!token) {
    return <Navigate to={`/login?next=${window.location.pathname.substring(1)}`} replace />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;