import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AdultClassHeader from './Classes/AdultClassHeader';
import ClassesNavigation from './Classes/ClassesNavigation';

const AdultClass = props => {
  const styles = {
    title: {
      width: '100%',
      textAlign: 'center'
    },
    classSchedule: {
      marginTop: 30
    }
  }

  return (
    <Grid
      container
      direction='row'
    >
      <Grid
        item
        xs={12}
      >
        <AdultClassHeader />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <ClassesNavigation />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h4"
          component="div"
          style={styles.title}
        >
          社會界研究班簡介
        </Typography>
      </Grid>

      <Grid item xs={1} md={2}></Grid>
      <Grid
        item
        xs={10}
        md={8}
      >
        <Typography
          component="div"
        >
          <p>
            鑑於道親人數遞增，發一崇德道場自一九八○年代起，開設五年進修班，分別設立新民班、至善班、培德班、行德班及崇德班，各班皆有其設班之宗旨，主要定位為成全道親、培育辦事人員及廣泛通才之基礎教育。課程由淺而深；由廣而精，以【五教】經典為主，淨化我們的心靈，將道融入日常生活當中，使自己成為一個有道之士、謙謙君子。
          </p>
          <p>
            <strong>法會</strong>
            <br />
            又名【率性進修班】，通常利用連續兩天或三天的假期，由有德行、修為好的點傳師們以及修辦多年的講師們，以生動幽默的言談，對人生哲理及道的尊貴做廣泛的探討分析。而在此過程中，時有仙佛慈悲臨壇說法，以期啟發班員的智慧。因此法會畢班後，班員皆有共同的感受——猶如天蠶再變，對人生充滿希望；對自己充滿信心。
          </p>
          <p>
            <strong>新民班簡介</strong>
            <br />
            凡有根基、有佛緣能於法會畢班後，班員可進入【新民班】研究道理，因為發心上新民班有大利益且完成此班及出席率達一定標準的班員，可立愿成為【辦事人員】之天職。
            【新民班】可以蒙受佛光普照、增福慧、改變命運。凡人在生活中找不到真理，庸人在生命中開不啟智慧。人生旅途，命運多舛，變幻莫測，然仙佛卻了了分明，並且時時守護在我們身邊，幫助我們在修行路上，隨緣了舊業。終究能平安順利，一路順風。
            <br />
            【新民班】可以讓內心更有力量、彩繪幸福人生。幸福是努力經營者的權利，美滿是心靈淨化的成果。因為持續的學道，浸潤在聖賢仙佛的智慧之光，讓我們退去久遠累劫，所累積的毛病習氣，法語的滋潤，如滴水穿石般瓦解我們頑固的個性、提昇心境、變化氣質。使我們更成長，走向廣濶的天空，彩繪幸福的人生。
            <br />
            【新民班】能夠開發智慧力，提升專注力。知識向外求得，令人顯耀；智慧向內開發，使人圓滿。學道明理可以增長智慧，遇到事情會用腦不煩惱，積極不著急、理事圓融、利己利人、 創造多贏、皆大歡喜。提升專注力，不受外在的干擾，而且推陳出新，不論做什麼工作，皆能左右逢源，事事圓滿。
            <br />
            【新民班】可以淨化心靈、轉變心念、自在快樂。淨化心靈是通往宇宙大能場的要訣，三千大千世界，多彩繽紛，令人目不暇給。上【新民班】，讓我們內心充電有能量，讓我們能夠轉念。 遇任何事皆以善心、善法、善行、善解來面對。自助天助，深信沒有解不開的難題，突破不了的障礙。人生自在從容，安心快樂。
            <br />
            【新民班】有自覺的學道、建立正知正見、豐富生命的內涵。每個人都在當下，創造永恆的歷史——求道讓我們找到回天的路；參加法會讓我們肯定道真、理真、天命真。進而參加新民班，循序漸進的學道參辦，對道理就會越來越明白；內心會越來越充實；生活會越來越快樂；家庭越來越和諧；生命越來越圓滿。
            <br />
            過了今天, 才有明天；有了當下，才有將來。所以有佛緣、有根基、有福氣、有智慧的您，趕快行動吧！
          </p>
          <p>
            <strong>至善班簡介</strong>
            <br />
            完成【新民班】的基礎學道，即可升入【至善班】繼續學道，期許自己更上一層樓。且完成此班及出席率達一定標準的班員，可立愿成為【講員】之天職。至善班有下列特點：
            <br />
            力行修身齊家的道化生活——家是創造幸福的根源，是個人修道的好園地，若能從自身修養做起，進而建立和樂的家庭，父慈子孝，夫義婦順，兄友弟恭，則社會必定祥和安康。所謂修天道從人道做起，要將道融入生活中，修身齊家，共同創造彌勒家園。然美麗的願景，是要逐步穩健踏出，才能達成的。
            <br />
            肯定天命的傳承——先天大道之寶貴，重在天命﹔然天命有明有晦，有隱有顯，有降有收，有時齊渡，有時涅槃。所謂「有緣遇著佛出世，無緣遇著佛涅槃。」又云：「來得早，不如來得巧。」如今正逢三期末劫，上天垂降真天命，授命明師指授千古不輕傳之祕寶，使修道者各個知道真道根源至善之處，才知由這根源處修起，將來達本還源、歸根復命。
            <br />
            養生之道——修天道必須從人道做起，《孝經》有云：「身體髮膚，受之父母，不敢毀傷，孝之始也。。。」因此，研究養生之道，保持身體健康，是行孝的起步，也是學道、修道、講道、辦道的基礎。
            <br />
            體認天恩師德——老大慈大悲，天道降世，師尊師母二位老大人，奉無極老之命，真傳真授，天命明師一指，讓我們脫離六道輪迴地獄，直返天堂。我們求道之人，應時時刻刻感念天恩師德。俗話說: 「一日為師終身為父」，何其榮幸能拜在師尊師母門下。故天道弟子不能不瞭解天恩師德。師德浩大助我了大愿 、助我消罪愆、助我脫苦淵。
            <br />
            仙佛慈訓——藉由仙佛之慈訓，字字珠璣、句句妙語，讓我們在修道的路途中增添智慧，引導我們啟迷轉悟。
            <br />
            尚有諸多精彩的課程等您來共襄盛舉！
          </p>
          <p>
            <strong>培德班簡介</strong>
            <br />
            【培德班】是五年進修班之第三年課程，其教育目的在於培養內德、提升心境；堅定道心、渡化有緣佛子；積極投入、參與修辦；仁智並舉、知行合一；參研內聖外王之道。故課程內容包涵了下列主題：
            <br />
            建立修道的人生觀——上天給我們每個人一段時間，也許長短不一，我們稱之為「人生」。之後這條人生旅途，怎麼走，端看我們了。可以越走越寛，我們稱之為「康莊大道」；也可以越走越窄，我們稱之為「羊腸小道」；甚至「走投無路」。然白陽修士肯定道的至尊至貴，唯理是從，體認只有修道，才是最根本的。
            <br />
            成全道親的要領——成全道親乃慈悲心、愛心、耐心與信心的展現。聖賢仙佛之所以不平凡，乃因其志向、發心、愿力之偉大。立志發心了愿，學習聖賢仙佛的行誼乃是白陽修士的日常功課。
            <br />
            白陽修士的生活規劃——在家出家是白陽修士的特色。然在這紅塵諸多的誘惑中，如何在塵不染塵，其生活規劃是不可或缺的一環。透過這個課程可讓我們在修道的路途中以凡養聖，聖凡兼修，讓生命更有意義、人生更有價值。
            <br />
            仙佛慈訓——藉由仙佛之慈訓，字字珠璣、句句妙語，讓我們在修道的路途中增添智慧，引導我們啟迷轉悟。
            <br />
            經典研究——【培德班】主要研究的經典有《清靜經》、《中庸釋證》及《禮記儒行篇》。藉由各教經典來印證道的尊貴。
            <br />
            以及諸多精彩的課程等您來共襄盛舉！
          </p>
          <p>
            <strong>行德班簡介:</strong>
            <br />
            【行德班】是五年進修班之第四年課程，能上至此班的班員實屬不易，皆是有大根器之人。【行德班】的教育目的在於充實道學，並提升修辦能力；「認準金線，護持天命」；「承擔使命，開創三多」；「尊師重道，報恩了愿」及學習內聖外王之道。所以課程安排理念與實務結合的相關內容，共包含：
            <br />
            尊師重道與報恩了愿——尊師必須重道，尊師與重道實不可分。蓋因師的本意傳道，故道不可不重；而道在師傳，故不得不尊。【知恩容易報恩難】！能知恩、感恩才會有報恩之愿行；無愿不成佛與仙，能立愿、了愿、盡忠、盡孝，才能成就立德、立言、立功之不朽聖業。
            <br />
            白陽修士應有的修持——白陽修士的殊勝因緣於「在塵不染塵」，是聖凡兼修的白陽天使，為參與大開普渡的神聖任務。故平日修持，應當用心經營以提昇正己成人的功夫。而精進修持的動力來源於對生命的熱愛、對真理的熱愛、對一貫道修持法的肯定。
            <br />
            推動基層道務的要領——如何讓佛堂在穩定中成長，必須要開創、要發展。如何讓道親明理，必須要落實學習，真修實煉，知行合一。基層道務穩定，佛堂才有實力，道務才能永續發展。
            <br />
            仙佛慈訓——藉由仙佛之慈訓，字字珠璣、句句妙語，讓我們在修道的路途中增添智慧，引導我們啟迷轉悟。
            <br />
            經典研究——【行德班】主要研究的經典有《心經》與《六祖壇經》。藉由各教經典來印證道的尊貴。
            <br />
            以及諸多精彩的課程等您來共襄盛舉
          </p>
          <p>
            <strong>崇德班簡介:</strong>
            <br />
            【崇德班】是發一崇德五年進修班之最後一個班程，能堅持到這個班程的班員，除了對道的肯定，也是道場的棟樑及未來講師培訓的人選。然欲代天宣化，需有正知正見，扮演承上啟下的橋樑，故提升與落實學、修、講、辦、行之能力以達正己成人是【崇德班】教育目的。課程包含：
            <br />
            如何落實學修講辦行——學道者效聖法賢，貫徹前人秉仙佛慈悲所揭示的精神指標：「學道越學越和氣，修道越修越歡喜，講道越講入性理，辦道越辦越契機，行道越行越中矩」，才能真正帶領初學者由迷返覺，由俗邁聖。因此，「如何學修講辦行」這堂課便是藉往聖修行心路歷程，及今之前賢大德修行心得之闡述，使我們能在迷茫之世間找到心中的燈塔，循序漸進，終達佛地。
            <br />
            前人的修辦精神——前人是我們道場的永恆舵手，一生奉獻中有許多智慧的理念，有許多成功的經驗是崇德人所必須要了解的，更是要效法學習前人的修辦精神，也是成功的最佳典範。前人的睿智理念與作法，不僅使道場開創有成，亦使道務快速成長，更可貴的是，對天命永昌，道場永續經營，及至可大可久，同時進行，並一併作好紮根的工作，奠定傳承永續的基石。
            <br />
            設立佛堂之殊勝——佛堂之殊勝有三曹普渡的據點；創造人間淨土；建造苦海中的大法船；行功了愿，福慧雙修的好地方；佛緣加厚，廣結善緣的好場所；佛力加持，有助修持；早晚禮佛，身心健康。仙佛護佑，遇難呈祥。仙佛慈悲：『能求道是有緣；能辦道是有份。有緣有份就是根基深厚，能設立佛堂幫助上天普渡眾生，更是有使命、有愿立的人。』
            <br />
            仙佛慈訓——藉由仙佛之慈訓，字字珠璣、句句妙語，讓我們在修道的路途中增添智慧，引導我們啟迷轉悟。
            <br />
            經典研究——【崇德班】主要研究的經典有《孟子》、《莊子》與《妙法蓮華經》。藉由各教經典來印證道的尊貴。
            <br />
            以及諸多精彩的課程等您來共襄盛舉「來得早，不如來得巧。」
          </p>
        </Typography>
      </Grid>
      <Grid item xs={1} md={2}></Grid>
    </Grid>
  )
}

export default AdultClass;