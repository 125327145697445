import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import UserProvider from './provider/UserProvider';

import PageWrapper from './components/PageWrapper';
import Dashboard from './components/Dashboard';
import Preferences from './components/Preferences';
import Login from './components/Login';
import Home from './components/Home';
import About from './components/About';
import Admin from './components/Admin';
import Experience from './components/Experience';
import History from './components/History';
import Vege from './components/Vege';
import VegeById from './components/Vege/VegeById';
import Classes from './components/Classes';
import LittleAngel from './components/LittleAngel';
import DuJing from './components/DuJing';
import VeryLittleAngel from './components/VeryLittleAngel';
import MiddleSchool from './components/MiddleSchool';
import AdultClass from './components/AdultClass';
import ContactUs from './components/ContactUs';
import ProtectedRoute from './components/ProtectedRoute';
import News from './components/News';
import Blog from './components/Blog';
import useToken from './hooks/useToken';
import { FYCD_GOOGLE_API_KEY } from './util/constants';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

function App() {

  const { token, setToken } = useToken();

  // if (!token) {
  //   return <Login setToken={setToken} />
  // }

  return (
    <UserProvider>
      <GoogleOAuthProvider clientId={FYCD_GOOGLE_API_KEY}>
      <BrowserRouter>
        <Routes>
          <Route element={<PageWrapper />}>
            <Route index element={<Home />} />
            <Route path="/login" element={<Login token={token} setToken={setToken} />} />
            <Route path="/about" element={<About />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/history" element={<History />} />

            <Route path="/vege" element={<Vege />} />
            <Route path="/vege/:id" element={<VegeById />} />

            <Route path="/classes" element={<Classes />} />
            <Route path="/littleangel" element={<LittleAngel />} />
            <Route path="/dujing" element={<DuJing />} />
            <Route path="/verylittleangel" element={<VeryLittleAngel />} />
            <Route path="/middleschool" element={<MiddleSchool />} />
            <Route path="/adultclass" element={<AdultClass />} />

            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/news" element={<News />} />

            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<Blog />} />

            {/* <Route path="/escriptures" element={<Escripture />} /> ---- escripture page */}
            
            {/* <Route path="/middleschool/lectures" element={<MiddleSchoolLectures />} /> ---- middleSchoolLectures page */}
            {/* <Route path="/adultclass/lectures" element={<AdultClassLectures />} /> ---- adultClassLectures page */}

            
            {/* <Route element={<ProtectedRoute isAllowed={!!token} />}>
              <Route path="/preferences" element={<Preferences token={token} />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Route> */}

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={!!token && token.roles && token.roles.includes('dashboard')}
                  token={token}
                >
                  <Dashboard token={token} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/preferences"
              element={
                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={!!token && token.roles && token.roles.includes('preferences')}
                  token={token}
                >
                  <Preferences token={token} />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/admin"
              element={
                <ProtectedRoute
                  redirectPath="/"
                  isAllowed={!!token && token.roles && token.roles.includes('admin')}
                  token={token}
                >
                  <Admin token={token} />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Route>
        </Routes>
      </BrowserRouter>
      </GoogleOAuthProvider>
    </UserProvider>
  );
}

export default App;
