import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import NewsCalendar from './News/NewsCalendar';
import DisplayNews from './News/DisplayNews';
import Loading from '../components/common/Loading';
import { getCalendarList } from '../util/calendarUtil';

const News = props => {
  const [ year, setYear ] = useState('');
  const [ month, setMonth ] = useState('');    // real month value
  const [ news, setNews ] = useState([]);
  const [ showLoading, setShowLoading ] = useState(false);

  useEffect(() => {
    const now = new Date();
    const settingNews = window.localStorage.getItem('settingNews');

    let newsYear = null;
    let newsMonth = null;

    if (settingNews && (Date.now() - parseInt(settingNews)) < 24*60*60*1000) {
      newsYear = window.localStorage.getItem('newsYear');
      newsMonth = window.localStorage.getItem('newsMonth');
    }

    let showYear = newsYear;
    let showMonth = newsMonth;

    if (newsYear) {
      setYear(parseInt(newsYear));
    } else {
      const thisYear = now.getFullYear();
      setYear(thisYear);
      window.localStorage.setItem('newsYear', thisYear);
      showYear = thisYear;
    }

    if (newsMonth) {
      setMonth(parseInt(newsMonth));
    } else {
      const thisMonth = now.getMonth();
      setMonth(thisMonth+1);
      window.localStorage.setItem('newsMonth', thisMonth+1);
      showMonth = thisMonth+1;
    }

    getCalendarFromBackend({ month: showMonth, year: showYear });
  }, []);

  const styles = {
    title: {
      width: '100%',
      textAlign: 'center',
      marginTop: 20
    },
    dates: {
      width: '100%',
      textAlign: 'center',
      marginTop: 30
    },
    news: {
      width: '100%',
      marginTop: 30,
      marginBottom: 30
    }
  };

  const getCalendarFromBackend = async ({ month, year }) => {
    setShowLoading(true);
    try {
      const theCalendarList = await getCalendarList({ month, year });
      setNews(theCalendarList.calendarList);
      setShowLoading(false);
    } catch (e) {
      console.error(e);
      setShowLoading(false);
    }
  }

  const updateYear = val => {
    setYear(val);
    window.localStorage.setItem('newsYear', val);
    window.localStorage.setItem('settingNews', Date.now());
    getCalendarFromBackend({ month, year: val });
  }

  const updateMonth = val => {
    setMonth(val);
    window.localStorage.setItem('newsMonth', val);
    window.localStorage.setItem('settingNews', Date.now());
    getCalendarFromBackend({ month: val, year });
  }

  return (
    <Grid
      container
      direction='row'
    > 
      <Grid
        item
        xs={12}
        style={styles.title}
      >
        <Typography
          variant="h4"
          component="div"
        >
          最新消息
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={styles.dates}
      >
        <NewsCalendar
          selectedYear={year}
          selectedMonth={month}
          setYear={updateYear}
          setMonth={updateMonth}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={styles.dates}
      >
        {
          showLoading ? (
            <Loading />
          ) : (
            <>
            {
              news.length > 0 && (
                <DisplayNews news={news} />
              )
            }
    
            {
              news.length == 0 && (
                <NewspaperIcon fontSize='large' />
              )
            }
            </>
          )
        }

        
      </Grid>
    </Grid>
  )
}

export default News;