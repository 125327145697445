import React from 'react';
import Grid from '@mui/material/Grid';

import LittleAngelHeader from './Classes/LittleAngelHeader';
import ClassesNavigation from './Classes/ClassesNavigation';
import DuJingClassSchedule from './Classes/DuJingClassSchedule';

const DuJing = props => {
  const styles = {
    title: {
      width: '100%',
      textAlign: 'center'
    },
    classSchedule: {
      marginTop: 30
    }
  }

  return (
    <Grid
      container
      direction='row'
    >
      <Grid
        item
        xs={12}
      >
        <LittleAngelHeader />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <ClassesNavigation />
      </Grid>

      <Grid
        item
        xs={12}
        style={styles.classSchedule}
      >
        <DuJingClassSchedule />
      </Grid>
    </Grid>
  )
}

export default DuJing;