
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { getNewEvents } from '../../util/apiUtils';

import { NUMBER_OF_EVENTS_TO_LOAD, INITIAL_NUMBER_OF_EVENTS } from '../../util/constants';

const LoadAllBlogs = props => {
  const [ events, setEvents ] = useState([]);
  const [ offset, setOffset ] = useState(0);
  const [ more, setMore ] = useState(true);

  useEffect(() => {
    loadEvents();
  }, []);

  const styles = {
    container: {
      marginTop: 30,
      marginBottom: 80
    },
    title: {
      color: '#999999',
      marginBottom: 20
    },
    eventPhoto: {
      width: '100%'
    },
    eventContainer: {
      paddingLeft: 40,
      paddingRight: 40
    },
    content: {
      width: '100%',
      maxHeight: 250
    },
    eventLink: {
      textDecoration: 'none'
    },
    showMoreButtonContainer: {
      marginTop: 30
    },
    showMoreButton: {
      color: '#259200',
      borderColor: '#259200'
    }
  }

  const handleShowMore = _ => {
    loadEvents();
  }

  const loadEvents = _ => {
    const getEvents = async() => {
      let count = NUMBER_OF_EVENTS_TO_LOAD;
      if (events.length === 0) {
        count = INITIAL_NUMBER_OF_EVENTS;
      }
      const result = await getNewEvents({ count, offset });
      const allEvents = result.events;
      
      if (Array.isArray(allEvents) && allEvents.length > 0) {
        const existingEvents = [...events];
        setEvents([...existingEvents, ...allEvents]);
        const newOffset = offset + NUMBER_OF_EVENTS_TO_LOAD;
        setOffset(newOffset);
      }
      
      const moreToGo = result.more;
      setMore(moreToGo);
    }
    
    getEvents();
  }

  return (
    <Grid 
      container
      direction='row'
      style={styles.container}
    >
      <Grid 
        item
        container
        justifyContent='center'
        xs={12}
      >
        <Typography
          variant="h4"
          component="div"
          style={styles.title}
        >
          活動花絮
        </Typography>
      </Grid>
      <Grid 
        item
        xs={12}
      >
        <Grid
          container 
          direction='row'
          justifyContent='space-around'
          spacing={6}
          style={styles.eventContainer}
        >
        {
          events.map(function(event) {
            return (
              <Grid 
                item
                container
                xs={12}
                sm={6}
                md={4}
                key={event.photoId}
              >
                <Grid
                  item
                > 
                  <Grid
                    item
                    container
                    xs={12}
                  > 
                    <Typography
                      component="div"
                      style={styles.content}
                      noWrap
                      xs={12}
                    >
                      <Link style={styles.eventLink} to={`/blog/${event.photoId}`}>
                        <img src={event.photoUrl} style={styles.eventPhoto} alt={event.title} />
                      </Link>
                    </Typography>

                    <Typography
                      component="div"
                      style={styles.content}
                      noWrap
                      xs={12}
                    >
                      {event.title}
                    </Typography>

                    { event.date && (
                      <Typography
                        component="div"
                        style={styles.content}
                        noWrap
                        xs={12}
                      >
                        日期: {event.date}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
        </Grid>
      </Grid>
      {
        more && (
          <Grid 
            item
            container
            justifyContent='center'
            xs={12}
            style={styles.showMoreButtonContainer}
          >
            <Button 
              variant="outlined"
              onClick={handleShowMore}
              style={styles.showMoreButton}
            >顯示更多</Button>
          </Grid>
        )
      }
      
    </Grid>
  )
}

export default LoadAllBlogs;