import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import ExperienceContent from './Experience/ExperienceContent';
import { getExperienceByText } from '../util/apiUtils';
import { numOfExperienceToDisplay } from '../util/constants';

const Experience = props => {
  const [ searchText,  setSearchText ] = useState('');
  const [ experiences, setExperiences ] = useState([]);
  const [ toDisplay, setToDisplay ] = useState([]);
  const [ showMore, setShowMore ] = useState(false);

  const getExperiencesByApi = searchText => {
    let searching = false;
    if (!searching) {
      searching = true;
      setTimeout(async () => {
        const results = await getExperienceByText(searchText);
        // console.log('------results.escriptur/es:', results.escriptures)
        const allResponse = results.escriptures ? results.escriptures : [];

        setExperiences(allResponse);
        
        processExperiences(allResponse);
        searching = false;
      }, 1000);
    }
  }

  const processExperiences = allResponse => {
    let needToShowMore = allResponse.length >= toDisplay.length;
    // console.log('----needToShowMore:', needToShowMore)
    // console.log('----numOfExperienceToDisplay:', numOfExperienceToDisplay)
    // console.log('----toDisplay1:', toDisplay.length)

    const end = toDisplay.length + numOfExperienceToDisplay;

    // console.log('----allResponse:', allResponse.length)
    // console.log('----toDisplay2:', toDisplay.length)
    // console.log('----end:', end)
    const responseToDisplay = needToShowMore ? allResponse.slice(0, end) : [];
    setToDisplay(responseToDisplay);

    needToShowMore = allResponse.length > responseToDisplay.length;

    setShowMore(needToShowMore);
  }
  
  useEffect(() => {
    getExperiencesByApi('');
  }, []);

  useEffect(() => {
    if (searchText.length >= 2) {
      setToDisplay([]);
      setExperiences([]);
      setShowMore(false);
      getExperiencesByApi(searchText);
    }
  }, [searchText]);

  const styles = {
    aboutImageContainer: {
      width: '100%',
      maxHeight: 320,
      overflow: 'hidden'
    },
    aboutImage: {
      width: '100%'
    },
    searchBar: {
      backgroundColor: '#F2F3E3',
      padding: 15,
      textAlign: 'center'
    },
    content: {
      marginTop: 40
    },
    moreButton: {
      color: '#259200',
      borderColor: '#259200'
    }
  }

  const onSearchTextChange = event => {
    setSearchText(event.target.value);
  }

  const needToDisplayMore = _ => {
    processExperiences(experiences);
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
    >
      <Grid
        item
        style={styles.aboutImageContainer}
      > 
        <img style={styles.aboutImage} src='/img/escriptures/escriptures.jpg' alt='escriptures' />
        <Typography
          variant="h2"
          component="div"
          sx={{ position: 'absolute', top: { xs: 100, sm: 150 }, right: { xs: 100, md: 200 } }}
        >
          道的見證
        </Typography>
      </Grid>

      <Grid 
        item
        xs={12}
        style={styles.searchBar}
      >
        <TextField
          id="input-with-icon-textfield"
          onChange={onSearchTextChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                搜尋:
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Grid>

      <Grid
        item
        container
        xs={12}
        direction='row'
        style={styles.content}
      >
        <Grid item xs={1} />
        <Grid item xs={10}>
          {
            toDisplay.map((experience, i) => {
              return (
                <div key={i}>
                  <ExperienceContent experience={experience} />
                </div>
              )
            })
          }
        </Grid>
        <Grid item xs={1} />
      </Grid>

      {
        showMore ? (
          <Grid
            item
          >
            <Button 
              variant="outlined" 
              style={styles.moreButton}
              onClick={needToDisplayMore}
            >
              顯示更多文章
            </Button>
          </Grid>
        ) : null
      }
      
    </Grid>
  )
}

export default Experience;