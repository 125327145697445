import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AboutHeader from './About/AboutHeader';

const About = props => {
  const styles = {
    subtitle: {
      width: '100%',
      textAlign: 'center',
      marginTop: 20
    },
    aboutVideo: {
      marginTop: 20
    },
    aboutVideoIframe: {
      width: 560,
      height: 315,
      border: 0
    }
  }

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
    >
      <AboutHeader />

      <Typography
        variant="h5"
        component="div"
        style={styles.subtitle}
      >
        簡介
      </Typography>

      <Grid
        item
        style={styles.aboutVideo}
      >
        <iframe style={styles.aboutVideoIframe} src="https://www.youtube.com/embed/pVlBf9VfVJE" frameBorder="0" allowFullScreen></iframe>
      </Grid>

      <Grid
        item
        container
        direction='row'
      >
        <Grid item xs={1} />
        <Grid item xs={10}>
        <p>
            <strong>我們是…</strong>
            <br/>【光慧文教基金會】，是一家非營利文教慈善機構，於2008年成立於美國加州舊金山（三藩市），總部設在台灣台中市。所有志工皆是自願奉獻、義務服務的。
          </p>
          <br/>
          <p>
            <strong>我們的使命</strong><br/>光慧文教基金會的使命是推廣與復興中國固有道統文化和道德觀, 以文教、社教，結合慈善事業，加以宗教的博愛的情懷，期望對於現代社會朝向急功近利、冷漠淡薄的走向中，從心靈直至實質，注入一股溫情暖流，藉此拋磚引玉的行動，期對社會國家有整體性、全方位的回饋與貢獻。
          </p>
          <br/>
          <div>
            <strong>我們的目標</strong>
            <ol><li>借由多種語言的課程和活動移風易俗，幫助每個人更深刻體悟中華傳統文化的博大精深</li><li>藉循【至聖先師】孔子和古代聖賢之經典聖訓，以期幫助人人提升自我覺知並認識瞭解道德觀與日常生活乃密不可分</li><li>經由志工隊和各項課程幫助年輕的下一代，使其品格和人格健全發展，增強道德觀並感悟恪守中華文化傳統之重要性</li></ol>
          </div>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Grid>
  )
}

export default About;