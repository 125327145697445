import React from 'react';
import Grid from '@mui/material/Grid';

const MiddleSchoolHeader = props => {
  const styles = {
    imageContainer: {
      width: '100%',
      overflow: 'hidden',
      maxHeight: 300,
      display: 'block'
    },
    image: {
      width: '100%',
      position: 'relative',
      xs: { top: 0 },
      md: { top: -80 }
    },
    finalImage: {
      width: '100%',
    }
  }

  return (
    <Grid style={styles.imageContainer}>
      <Grid 
        style={styles.image}
        sx={{ top: {xs: 0, md: -40, lg: -80 }}}
      >
        <img style={styles.finalImage} src='/img/class/middleSchool.jpg' alt='Middle School' />
      </Grid>
    </Grid>
  )
}

export default MiddleSchoolHeader;